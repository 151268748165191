import * as React from "react"
import Fetali1 from '../components/Fetali1/Fetali1';
import Fetali2 from '../components/Fetali2/Fetali2';
import Fetali3 from '../components/Fetali3/Fetali3';
import Fetali4 from '../components/Fetali4/Fetali4';
import Fetali5 from '../components/Fetali5/Fetali5';
import Fetali6 from '../components/Fetali6/Fetali6';
import Fetali7 from '../components/Fetali7/Fetali7'

import "../components/layout.css";

const IndexPage = () => {
  const forWhomRef = React.createRef();
  const howItWorksRef = React.createRef();
  const contactRef = React.createRef();

  const scrollToForWhom = () => {
    forWhomRef.scrollTo(0, 0);
  }

  const scrollToHowItWorks = () => {
    howItWorksRef.scrollTo(0, 0);
  }

  const scrollToContact = () => {
    console.log('e')
    contactRef.scrollTo(0, 0);
  }

  return (
    <>
      <Fetali1
        scrollToForWhom={() => scrollToForWhom}
        scrollToHowItWorks={() => scrollToHowItWorks}
        scrollToContact={scrollToContact}
      />
      <Fetali2 />
      <Fetali3 />
      <Fetali4 />
      <Fetali5 />
      <Fetali6 />
      <Fetali7 contactRef={contactRef}/>
    </>
  )
}

export default IndexPage
