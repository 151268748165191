import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { MdLocalPhone } from 'react-icons/md';
import ContactItem from './ContactItem';
import logotype0 from '../../assets/logotype0.png';
import logotype1 from '../../assets/logotype1.png';
import logotype2 from '../../assets/logotype2.png';
import logotype3 from '../../assets/logotype3.png';
import * as S from './Fetali7.styles';

const contactItems = [
 /* {
    icon: (
      <MdLocalPhone
        style={{ color: '#2dafef', width: '32px', height: '32px', margin: '0 13px 0 0' }}
      />
    ),
    header: 'Telefon',
    text: ['(+48) 796 686 376', '(+48) 796 686 376'],
  },*/
  {
    icon: (
      <FaMapMarkerAlt
        style={{ color: '#2dafef', width: '32px', height: '32px', margin: '0 13px 0 0' }}
      />
    ),
    header: 'Adres',
    text: ['Fetai Health Sp. z o.o.', 'Pl. Kilińskiego 2, 35-005 Rzeszów', 'NIP: 517-040-61-23', 'KRS:0000836931'],
  },
  {
    icon: (
      <GoMail style={{ color: '#2dafef', width: '32px', height: '32px', margin: '0 13px 0 0', position: 'relative', bottom: '4px' }} />
    ),
    header: 'E-mail',
    text: ['wiktoria@fetaihealth.pl'],
  },
];

export default () => (
  <S.Wrapper id="contact">
    <S.InnerWrapper>
      <S.Header>Kontakt</S.Header>
      <S.Spacer />
      <S.Text>
        Obecnie projekt realizowany jest przez fundusz inwestycyjny Polish Venture Fund przy
        współfinansowaniu Narodowego Centrum Badań i Rozwoju w ramach programu Bridge Alfa.
        <br />
        <strong>
          Szukamy inwestorów gotowych zainwestować działalność spółki w kontekście wejścia na rynek
          oprogramowania medycznego.
        </strong><br />
		Kontakt inwestycyjny: www.polishventurefund.pl
      </S.Text>
      <S.ContactDataWrapper>
        {contactItems.map((item: any, index) => (
          <ContactItem key={index} {...item} />
        ))}
      </S.ContactDataWrapper>
    </S.InnerWrapper>
    <S.Footer>
      <S.FooterBody>
        <S.CopyRight>
          <S.Bold>© 2021 Fetai Health</S.Bold>
        </S.CopyRight>
        <S.FoundsWrapper>
		<S.EuropeFunds src={logotype0} />
          <S.EuropeFunds src={logotype1} />
          <S.EuropeFunds src={logotype2} />
          <S.EuropeFunds src={logotype3} />
        </S.FoundsWrapper>
      </S.FooterBody>
    </S.Footer>
  </S.Wrapper>
);
