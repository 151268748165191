import styled from 'styled-components';
import BG2 from '../../assets/fetai/fetaliBG2.png';

export const Wrapper = styled.section`
  min-height: 100vh;
  background-color: #e7e7e7;
  width: 100%;
  box-sizing: border-box;
  background-image: url(${BG2});
  background-position: center;
  background-size: cover;
`;

export const InnerWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding: 73px 0 0;
`;

export const Header = styled.h2`
  font-family: 'Causten Black';
  font-size: 59px;
  font-weight: 300;
  line-height: 0.92;
  max-width: 609px;
  color: #404041;
  width: 100%;
  text-align: center;
  margin: 2% auto 0;

  @media(max-width: 400px) {
    font-size: 45px;
  }
`;

export const Spacer = styled.div`
  width: 94px;
  height: 6px;
  background-color: #2dafef;
  margin: 27px auto 45px;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1325px;
  margin: 0 auto;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 48px #808080b5;
  padding: 46px 28px 54px;
  width: 352px;
  height: 330px;
  margin: 0 29px 76px;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;

  @media(max-width: 400px) {
    padding: 8px;
  }
`;

export const ItemIcon = styled.img`
  max-width: 95px;
  max-height: 95px;
  margin: 0 auto 22px;
  object-fit: contain;
  border-radius: 100%;
`;

export const ItemText = styled.p`
  font-family: 'Causten Extra Bold';
  font-size: 25px;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
  color: #404041;
  margin: 0;
  min-height: 90px;
`;
