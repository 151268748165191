import styled from 'styled-components';

export const Wrapper = styled.section`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
`;

export const InnerWrapper = styled.div`
  max-width: 1360px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-top: 70px;
`;

export const Header = styled.h2`
  margin: 0;
  width: 100%;
  font-family: 'Causten Black';
  font-size: 59px;
  font-weight: 400;
  line-height: 0.92;
  text-align: center;
  color: #000;

  @media(max-width: 400px) {
    font-size: 45px;
  }
`;

export const Spacer = styled.div`
  width: 93px;
  height: 7px;
  background-color: #2dafef;
  margin: 41px auto 62px;
`;

export const Text = styled.p`
  width: 100%;
  font-family: 'Causten Regular';
  font-size: 25px;
  font-weight: 300;
  line-height: 1.44;
  text-align: center;
  color: #747474;
  margin: 0 0 73px;
`;

export const ContactDataWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  padding: 32px 0;
`;

export const Footer = styled.footer`
  width: 100%;
  min-height: 178px;
  background-color: #fff;
  margin: auto 0 0;
  display: flex;
  flex-wrap: wrap;
`;

export const FooterBody = styled.div`
  box-sizing: border-box;
  max-width: 1420px;
  width: 100%;
  padding: 0 16px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const CopyRight = styled.p`
  font-family: 'Causten Regular';
  font-size: 19px;
  text-align: center;
  color: #707070;
  min-width: 458px;
  padding: 27px 0;

  @media(max-width: 500px) {
    min-width: auto;
  }
`;

export const Bold = styled.span`
  font-family: 'Causten Black';
`;

export const ItemWrapper = styled.div`
  display: flex;
  min-width: 182px;
  margin-bottom: 18px;

  @media(max-width: 880px) {
    min-width: 100%;
    padding-left: 30%;
  }

  @media(max-width: 400px) {
    padding-left: 20px;
  }
`;

export const ItemIcon = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 13px;
  color: #2dafef;
`;

export const ItemBody = styled.div``;

export const ItemHeader = styled.h6`
  font-family: 'Causten Regular';
  font-size: 20px;
  font-weight: bold;
  color: #224750;
  margin: 0;
`;

export const ItemText = styled.p`
  margin: 12px 0 0;
  font-family: 'Causten Regular';
  font-size: 18px;
  color: #707070;
`;

export const EuropeFunds = styled.img`
  object-fit: contain;
`;

export const FoundsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 2;
`;
