import styled from 'styled-components';

interface BgColorProp {
  background: string;
}

export const Wrapper = styled.section<BgColorProp>`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-image: url(${(props) => props.background});
  background-size: contain;
`;

export const InnerWrapper = styled.div`
  max-width: 1384px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 11%;

  @media (max-width: 874px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  max-width: 653px;
  margin: 0 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 874px) {
    margin: 0 auto 79px;
  }
`;

interface HeaderProps {
  color: string;
  headerStyles: string;
}

export const Header = styled.h2<HeaderProps>`
  margin: 0 0 79px;
  width: 100%;
  min-height: 96px;
  font-family: "Causten Black";
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  max-width: 422px;
  color: ${(props) => props.color};
  ${(props) => props.headerStyles};

  @media(max-width: 400px) {
    font-size: 45px;
  }
`;

export const Text = styled.p`
  font-family: "Causten Regular";
  font-size: 22px;
  line-height: 1.59;
  color: #5e5e5e;
  width: 675px;
  width: 100%;
`;
