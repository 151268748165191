import styled from "styled-components"

export const Wrapper = styled.section<any>`
  background-color: ${props => props.bgColor};
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  background-image: url(${props => props.background});
  background-size: contain;
`

export const BodyWrapper = styled.div`
  min-width: 1150px;
  flex-grow: 1;
  display: flex;
  justify-content: center;

  @media(max-width: 1150px) {
    padding-right: 0;
    min-width: initial;
  }

  @media(max-width: 470px) {
    padding: 0 0 37px;
  }
`;

export const Body = styled.div<any>`
  max-width: 915px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  ${props => props.bodyStyles}
`

export const ImageContainer = styled.div`
  min-height: 1000px;
  flex-grow: 1;
  overflow: hidden;
  height: 100vh;
  object-position: right;
  object-fit: cover;

  @media(max-width: 1150px) {
    display: none;
  }
`;

export const SideImage = styled.img`
  min-height: 1000px;
  flex-grow: 1;
  height: 100vh;
  object-fit: cover;
  object-position: left;

  @media(max-width: 1000px) {
    display: none;
  }
`