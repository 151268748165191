import React from 'react';
import * as S from './DiamaticWrapper.styles';

export default ({
  img,
  children,
  isReversed = false,
  bgColor = '#fff',
  bodyStyles = '',
  background,
  id,
}: {
  bgColor?: string;
  isReversed?: boolean;
  img: string;
  children: React.ReactChild;
  bodyStyles?: string;
  background: string;
  id?: string;
}) => (
  <S.Wrapper bgColor={bgColor} background={background} id={id}>
    <S.BodyWrapper>
        <S.Body bodyStyles={bodyStyles} isReversed={isReversed}>
          {children}
        </S.Body>
    </S.BodyWrapper>
      <S.ImageContainer>
        <S.SideImage src={img} />
      </S.ImageContainer>
  </S.Wrapper>
);
