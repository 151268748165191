import React from 'react';
import Item from './Item';
import fetali_2_1 from '../../assets/fetai/fetali_2_1.png';
import fetali_2_2 from '../../assets/fetai/fetali_2_2.png';
import fetali_2_3 from '../../assets/fetai/fetali_2_3.png';
import fetali_2_4 from '../../assets/fetai/fetali_2_4.png';
import fetali_2_5 from '../../assets/fetai/fetali_2_5.png';
import * as S from './Fetali2.styles';

const items = [
  {
    icon: fetali_2_1,
    text: 'Nieinwazyjna diagnostyka prenatalna',
  },
  {
    icon: fetali_2_2,
    text: 'Wczesne przeciwdziałanie dysplazji układu szkieletowego',
  },
  {
    icon: fetali_2_3,
    text: 'Badanie bezpieczne dla matki i dziecka',
  },
  {
    icon: fetali_2_4,
    text: 'Wsparcie zabiegów prenatalnych',
  },
  {
    icon: fetali_2_5,
    text: `Szansa na lepszą opiekę\xa0neonatologiczną`,
  },
];

export default () => (
  <S.Wrapper id="for-whom">
    <S.InnerWrapper>
      <S.Header>FetaiHealth to:</S.Header>
      <S.Spacer />
      <S.BodyWrapper>
        {items.map((item: any, index) => (
          <Item {...item} key={index} />
        ))}
      </S.BodyWrapper>
    </S.InnerWrapper>
  </S.Wrapper>
);
