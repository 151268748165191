import React from 'react';
import DiamaticColumnWrapper from '../DiamaticColumnWrapper/DiamaticColumnWrapper';
import BG4 from '../../assets/fetai/fetaliBG4.png';

const data = [
  {
    title: 'Wyzwania wczesnej diagnostyki',
    body: `Choć dysplazje ujawnia się już na etapie życia płodowego, nie ma jednej, skutecznej metody wykrywania schorzeń z tej grupy. Większość dzieci diagnozowana jest dopiero po urodzeniu, co znacznie utrudnia prowadzenie właściwej terapii i opóźnia kontakt z wyspecjalizowanymi placówkami medycznymi. Lekarze ginekolodzy w swojej praktyce opierają się na takich metodach jak badania radiologiczne i USG. Choć badanie ultrasonograficzne pozwala na dokładne obrazowanie płodu i przeprowadzenie dość dokładnych pomiarów antropometrycznych, wielu specjalistów nie jest w stanie na jego podstawie odróżnić dysplazji od innych chorób o podłożu genetycznym objawiających się dysproporcjami w budowie układu kostnego. `,
  },
  {
    title: 'Przełom w diagnostyce dysplazji',
    body: `Technologia FetaiHealth to rewolucyjne wsparcie dla lekarzy położników prowadzących wczesną diagnostykę wad płodu. System wykorzystuje moduły sztucznej inteligencji, które dokonują szczegółowych pomiarów antropometrycznych obrazu uzyskanego na podstawie trójwymiarowego badania USG. Metoda jest w pełni bezpieczna, nieinwazyjna i nie wymaga zakupu żadnego dodatkowego sprzętu medycznego. Moduł AI analizuje takie parametry jak proporcje ciała płodu, jego obwód głowy i rozpiętość ramion i powiadamia lekarza, jeśli pojawiają się pewne cechy dysmorficzne, typowe dla dysplazji. `,
  },
];

export default () => <DiamaticColumnWrapper background={BG4} color="#404041" columns={data} />;
