import React from 'react';
import DiamaticWrapper from '../DiamaticWrapper/DiamaticWrapper';
import doctorWithSthetoscope from '../../assets/stetho.jpg';
import BG2 from '../../assets/fetai/fetaliBG2.png';
import * as S from '../Fetali3/Fetali3.styles';

export default () => (
  <DiamaticWrapper background={BG2} img={doctorWithSthetoscope}>
    <>
      <S.Header>Znaczenie diagnostyki prenatalnej</S.Header>
      <S.Spacer />
      <S.Text>
        Wczesne wykrycie wad genetycznych u dzieci umożliwia zapewnienie im właściwej opieki
        neonatologicznej a w wielu wypadkach także rozpoczęcie leczenia na etapie życia płodowego.
        Szczegółowe informacje zebrane przez system a także na drodze badania ultrasonograficznego
        3D umożliwiają chirurgowi prenatalnemu dokładne przestudiowanie przypadku przed operacją a
        co za tym idzie zminimalizowanie potencjalnego ryzyka dla zdrowia matki i dziecka.
        Trójwymiarowe wizualizacje mogą być przeglądane zarówno na ekranie komputera jak i przy
        pomocy gogli do wirtualnej rzeczywistości.
      </S.Text>
    </>
  </DiamaticWrapper>
);
