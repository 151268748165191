import styled from 'styled-components';

export const Header = styled.h2`
  margin: 25% 0 0;
  font-family: 'Causten Bold';
  font-size: 59px;
  font-weight: 400;
  line-height: 0.92;
  color: #404041;

  @media(max-width: 500px) {
    font-size: 48px;
  }

  @media(max-width: 400px) {
    font-size: 45px;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Causten Regular';
  font-size: 26px;
  line-height: 1.65;
  color: #5e5e5e;
  margin-bottom: 30px;
`;

export const BoldedBlue = styled.span`
  font-size: 30px;
  color: #2e3191;
  white-space: pre-line;
  font-weight: 800;
`;

export const Spacer = styled.div`
  width: 93px;
  height: 7px;
  background-color: #2dafef;
  margin: 16px auto 12% 0;
`;
