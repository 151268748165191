import React from 'react';
import DiamaticColumnWrapper from '../DiamaticColumnWrapper/DiamaticColumnWrapper';
import BG4 from '../../assets/fetai/fetaliBG4.png';

const data = [
  {
    title: 'Samouczące się algorytmy',
    body: `Głębokie sieci neuronowe wykorzystane w projekcie FetaiHealth bazują na dużych zbiorach danych medycznych zebranych podczas badań ultrasonograficznych 3D. Aby uniknąć jakichkolwiek pomyłek czy nadinterpretacji przed wprowadzeniem do systemu, dane weryfikowane są przez odpowiednio przeszkolonych lekarzy ginekologów i radiologów. System wykorzystuje zarówno algorytmy uczenia maszynowego jak i uczenia głębokiego, aby automatyczny pomiar części ciała płodu był jak najbardziej precyzyjny. W przyszłości możliwe będzie poszerzenie spektrum diagnozowanych za pomocą systemu chorób między innymi o hipotrofię symetryczną i asymetryczną czy zespół Downa. `,
  },
  {
    title: 'Wsparcie dla lekarza, wsparcie dla rodziców',
    body: `Okres ciąży i połogu to dla rodziców czas wzmożonego niepokoju o zdrowie dziecka. Z tego powodu wielu z nich stresem reaguje na wieści o możliwych nieprawidłowościach w rozwoju płodu. FetailHealth umożliwia przedstawienie rodzicom diagnozy w sposób przystępny i zrozumiały a zarazem przygotowanie ich na kolejne etapy leczenia. Dokładny obraz 3D płodu jest miarodajną informacją pozwalającą planować zabiegi neonatologiczne lub operacje w łonie matki. Rodzice nie muszą odbijać się od ściany skomplikowanej terminologii medycznej ani interpretować dwuwymiarowych zdjęć USG. Zamiast tego otrzymują niemal namacalną informację na temat tego, jak rozwija się ich dziecko. Co istotne, koszty takiej diagnostyki nie przekraczają możliwości finansowych przeciętnej rodziny a technologia jest powszechnie dostępna. `,
  },
];

export default () => <DiamaticColumnWrapper background={BG4} color="#404041" columns={data} />;
