import React from 'react';
import DiamaticWrapper from '../DiamaticWrapper/DiamaticWrapper';
import stetho from '../../assets/stetho.jpg';
import BG2 from '../../assets/fetai/fetaliBG2.png';
import * as S from './Fetali3.styles';

export default () => (
  <DiamaticWrapper background={BG2} img={stetho} id="how-it-works">
    <>
      <S.Header>Dysplazje układu szkieletowego</S.Header>
      <S.Spacer />
      <S.Text>
        To grupa chorób o podłożu genetycznym, na skutek których dochodzi do nieprawidłowego rozwoju
        kości i chrząstek. Spektrum objawów jest szerokie, od łagodnych, takich jak zapalenia
        stawów, przez zaburzenia wzrastania aż po ciężkie powikłania, które mogą doprowadzić do
        obumarcia płodu. Poza nieprawidłowościami w rozwoju kończyn i kręgosłupa, dysplazje mogą
        prowadzić do zaburzeń funkcji układu oddechowego, krążenia i upośledzenia wzroku i słuchu.
        Współczesna medycyna klasyfikuje ponad 400 rodzajów dysplazji, za występowanie których
        odpowiadają różne geny.
      </S.Text>
    </>
  </DiamaticWrapper>
);
