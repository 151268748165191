import React, { useState } from 'react';
import fetaiLogo from '../../assets/fetai/fetaiLogo.svg';
import fetaliLogoMobile from '../../assets/fetai/fetaliLogoMobile.svg';
import doctor2 from '../../assets/fetai/doctor2.jpg';
import scrollTo from 'gatsby-plugin-smoothscroll';
import logo from "../../assets/logo-unia.jpg";

import * as S from './Fetali1.styles';

export default () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.HeadWrapper>
          <S.HeadBody>
            <S.HeaderRow>
              
            </S.HeaderRow>
            <S.HeaderRow>
            
            </S.HeaderRow>
            <S.LogoHeader src={logo} />
          </S.HeadBody>
        </S.HeadWrapper>
        <S.NavBar isOpen={isMenuOpen}>
          <S.Logo src={fetaiLogo} />
          <S.MobileLogo src={fetaliLogoMobile} />
          <S.LinksWrapper>
            <S.StyledLink onClick={() => scrollTo('#for-whom')}>Dla kogo?</S.StyledLink>
            <S.StyledLink onClick={() => scrollTo('#how-it-works')}>Jak to działa?</S.StyledLink>
            <S.StyledLink onClick={() => scrollTo('#contact')}>Kontakt</S.StyledLink>
          </S.LinksWrapper>
          {isMenuOpen ? (
            <S.LinksMobileWrapperWrapper isActive={isMenuOpen}>
              <S.StyledLink onClick={() => scrollTo('#for-whom')}>Dla kogo?</S.StyledLink>
              <S.StyledLink onClick={() => scrollTo('#how-it-works')}>Jak to działa?</S.StyledLink>
              <S.StyledLink onClick={() => scrollTo('#contact')}>Kontakt</S.StyledLink>
            </S.LinksMobileWrapperWrapper>
          ) : null}
          <S.BurgerWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <S.Close /> : <S.Burger />}
          </S.BurgerWrapper>
        </S.NavBar>
        <S.Body>
		
		
          <S.MainPhoto src={doctor2} />
          <S.Column>
            <S.HeaderWrapper>
              <S.HeaderStrong>
                Nieinwazyjna
              </S.HeaderStrong>
              <S.HeaderNormal>
                diagnostyka prenatalna
              </S.HeaderNormal>
            </S.HeaderWrapper>
            <S.HeaderWrapper>
              <S.HeaderSmall>
                Tytuł projektu B+R: Wsparcie diagnostyki prenatalnej poprzez stworzenie
                systemu automatycznego wymiarowania płodów na podstawie
                trójwymiarowych danych ultrasonograficznych.
              </S.HeaderSmall>
			  <S.HeaderSmall>Grantobiorca: Fetai Health Sp. z o.o., Pl. Kilińskiego 2, 35-005 Rzeszów</S.HeaderSmall>
			  <S.HeaderSmall>Umowa: UMOWA O WSPARCIE (POWIERZENIE GRANTU) zgodnie z Projektem realizowanym w ramach Programu Operacyjnego Inteligentny Rozwój 2014-2020 Działanie 1.3: Prace B+R finansowane z udziałem funduszy kapitałowych Poddziałanie 1.3.1: Wsparcie Projektów badawczo-rozwojowych w fazie preseed przez fundusze typu proof of concept – BRIdge Alfa z dnia 23.01.2020</S.HeaderSmall>
			  
              <S.HeaderSmall>Całkowity koszty realizacji Projektu Grantowego: 1.000.000 PLN</S.HeaderSmall>
              <S.HeaderSmall>
                Dofinansowanie ze środków publicznych: 800 000,00 PLN – 80% dofinansowania budżetu Projektu Grantowego (środki przekazane przez Narodowe Centrum Badań i Rozwoju z siedzibą w Warszawie)
              </S.HeaderSmall>
			  <S.HeaderSmall>
			  Wsparcie prywatne realizacji projektu: 200 000,00 PLN – 20% budżetu Projektu Grantowego (środki przekazane przez Polish Venture Fund Sp. z o.o. Alternatywna Spółka Inwestycyjna Sp. komandytowa z siedzibą w Łodzi)
			  </S.HeaderSmall>
			  
			  <S.HeaderSmall>Okres realizacji: 01.02.2020 r. – 31.10.2021 r.</S.HeaderSmall>
              <S.HeaderSmall>
			   Projekt współfinansowany z Europejskiego Funduszu Rozwoju Regionalnego 
			   w ramach Programu Operacyjnego Inteligentny Rozwój 2014-2020
              </S.HeaderSmall>
              <S.HeaderSmall>
                Działanie 1.3: Prace B+R finansowane z udziałem funduszy kapitałowych Poddziałanie 1.3.1: Wsparcie Projektów badawczo-rozwojowych w fazie preseed przez fundusze typu proof of concept – BRIdge Alfa
              </S.HeaderSmall>
			  
              
            </S.HeaderWrapper>
            {/* <S.ButtonWrapper> */}
            {/* <S.MoreButton onClick={() => scrollTo('#more')}>Więcej</S.MoreButton> */}
            {/* </S.ButtonWrapper> */}
		
			
          </S.Column>
        </S.Body>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};
