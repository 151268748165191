import React from 'react';
import * as S from './Fetali7.styles';

export default ({
  icon,
  header,
  text,
}: {
  icon: JSX.IntrinsicElements;
  header: string;
  text: string[];
}) => (
  <S.ItemWrapper>
    {icon}
    <S.ItemBody>
      <S.ItemHeader>{header}</S.ItemHeader>
      {text.map((row: string, index) => (
        <S.ItemText key={index}>{row}</S.ItemText>
      ))}
    </S.ItemBody>
  </S.ItemWrapper>
);
