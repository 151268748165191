import { GoMail } from 'react-icons/go';
import styled from 'styled-components';
import BG1 from '../../assets/fetai/fetaliBG1.png';
import { MdClose, MdLocalPhone, MdMenu } from 'react-icons/md';

export const Wrapper = styled.section`
  min-height: 100vh;
  background-color: #f2f2f2;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-image: url(${BG1});
  background-position: center;
  background-size: cover;
`;

export const InnerWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  height: 100%;
`;

export const HeadWrapper = styled.header`
  height: 84px;
  width: 100%;
  background-color: #2dafef;
`;

export const BurgerWrapper = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  height: 41px;
  width: 41px;
  outline: none;

  @media(min-width: 781px) {
    display: none;
  }
`;

export const LogoHeader = styled.img`
  margin-bottom: 0;
  margin-left: auto;
`;

export const HeadBody = styled.div`
  max-width: 1432px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 354px;
  width: 100%;
`;

const Icon = `
  color: #f3f4f2; 
  width: 36px; 
  height: 36px; 
  margin-right: 8px;
`;

export const PhoneIcon = styled(MdLocalPhone)`
  ${Icon}
`;

export const MailIcon = styled(GoMail)`
  ${Icon}
`;

export const Description = styled.a`
  font-family: 'Causten Regular';
  font-size: 14px;
  font-weight: bold;
  color: #f3f4f2;
  text-decoration: none;
`;

export const NavBar = styled.nav<{isOpen: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1450px;
  width: 100%;
  margin: 0 auto 30px;
  padding: 23px 16px;
  box-sizing: border-box;
  ${props => props.isOpen && 'background-color: #f3f4f2;'}
`;

export const Logo = styled.img`
  object-fit: contain;

  @media (max-width: 746px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  @media (min-width: 746px) {
    display: none;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 781px) {
    display: none;
  }
`;

export const Burger = styled(MdMenu)`
  color: #2dafef;
  height: 41px;
  width: 41px;
`;

export const Close = styled(MdClose)`
  color: #2dafef;
  height: 41px;
  width: 41px;
`;

export const LinksMobileWrapperWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  flex-direction: column;
  background-color: #f3f4f2;
  position: absolute;
  top: 195px;
  left: 0;
  height: calc(100vh - 200px);
  transition: right 1.2s ease-out 0s;
  width: 100%;
  height: auto;
  align-items: center;
  border-bottom: 1px solid;

  @media(min-width: 781px) {
    display: none;
  }
`;

export const StyledLink = styled.div`
  font-family: 'Causten Regular';
  font-size: 19px;
  font-weight: bold;
  color: #404041;
  text-transform: uppercase;
  text-decoration: none;
  margin: 7px 15px;
  cursor: pointer;

  @media (max-width: 510px) {
    font-size: 16px;
  }
`;

export const Body = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;

  @media (max-width: 1145px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const MainPhoto = styled.img`
  max-width: 682px;
  height: auto;
  object-fit: cover;
  border-radius: 100%;
  flex-grow: 1;
  max-height: 682px;
  min-height: 682px;
  object-position: top;

  @media (max-width: 1322px) {
    max-width: 600px;
    max-height: 600px;
    min-height: 600px;
  }

  @media (max-width: 1248px) {
    max-width: 500px;
    min-width: 500px;
    max-height: 500px;
    min-height: 500px;
  }

  @media (max-width: 1145px) {
    margin-bottom: 37px;
  }

  @media (max-width: 700px) {
    max-width: 300px;
    min-width: 300px;
    max-height: 300px;
    min-height: 300px;
  }
`;

export const Column = styled.div`
  margin: 0 0 0 auto;
  max-width: 609px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 1145px) {
    margin: 0;
    width: 100%;
  }
`;

export const HeaderWrapper = styled.h1`
  font-family: 'Causten Regular';
  font-size: 59px;
  font-weight: 300;
  line-height: 0.92;
  max-width: 609px;
  color: #404041;

  @media (max-width: 1145px) {
    margin: 0 0 50px;
    width: 100%;
    text-align: center;
  }

  @media(max-width: 400px) {
    font-size: 45px;
  }
`

export const HeaderNormal = styled.div`
  font-family: 'Causten Regular';
  font-size: 59px;
  font-weight: 300;
  line-height: 0.92;

  @media (max-width: 1145px) {
    text-align: center;
  }

  @media(max-width: 400px) {
    font-size: 45px;
  }
`;

export const HeaderSmall = styled.div`
  padding-top: 20px;
  font-family: "Causten Regular";
  font-size: 24px;
  font-weight: 300;
  line-height: 0.92;

  @media (max-width: 1145px) {
    text-align: center;
  }

  @media (max-width: 400px) {
    font-size: 20px;
  }
`;

export const HeaderStrong = styled(HeaderNormal)`
  font-family: 'Causten Extra Bold';
`;


export const MoreButton = styled.button`
  width: 246px;
  height: 64px;
  border-radius: 32px;
  border: none;
  background-color: #2dafef;
  box-sizing: border-box;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'Causten Regular';
  font-size: 22px;
  font-weight: 900;
  line-height: 1.18;
  color: #fff;
  cursor: pointer;

  @media (max-width: 1145px) {
    margin: 0 auto;
  }
`;
